<template>
  <div class="actCredentials">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">凭证</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="actTitle"></div>
      <div class="actBg">
        <div class="topBox">
          <!-- <ImgDecypt class="avatarImg" round :src="userInfo.portrait" /> -->
          <div class="dottedLine">
            <div class="dotted" v-for="index in 60" :key="index"></div>
          </div>
          <div class="avatarImg"></div>
          <div class="qrText">
            <div>网黄Up的分享平台</div>
            <div>随时随地发现性鲜事</div>
          </div>
          <div class="dottedLine1">
            <div class="dotted" v-for="index in 60" :key="index"></div>
          </div>
          <div class="downloadUrlText">官网地址</div>
          <div class="downloadUrl">{{ officialUrl }}</div>
          <div class="qrcode">
            <div ref="qrCodeUrl"></div>
          </div>
          <div class="tipText">如果账号丢失，请到设置-账号凭证找回，上传凭证或扫码凭证</div>
          <div class="saveBtn" @click="showTip">截图保存账号凭证到手机</div>
        </div>
      </div>
      <div class="illustrateTitle">账号丢失不用愁，保存凭解君优</div>
      <div class="illustrateText">
        由于行业特殊性，当app无法使用需要下载新的安装包，以及系统升级等原因，用户进入app后自动生成了新的账号从而导致账号丢失。
      </div>
      <div class="illustrateText mb37">
        对此，账号丢失的用户可在我的-个人资料-账号凭证找回或扫码上传该凭证二维码，即可找回升级更新前的原账号而原账号的VIP等级等全套资料也将得到恢复。
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
// import ImgDecypt from "@/components/ImgDecypt"
import { queryCertificate } from '@/api/mine';
import { getSessionItem } from '@/utils/longStorage';
import { Toast } from 'vant';
export default {
  name: 'ActCredentials',
  components: {
    // ImgDecypt,
  },
  data() {
    return {
      qrUrl: '', // 二维码地址
      userInfo: '', // 用户信息
      officialUrl: '', // 官网
      actCredentialsBg: require('@/assets/png/actCredentialsBg.png'),
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    let appConfig = JSON.parse(getSessionItem('appConfig'));
    appConfig.sourceList.forEach((i) => {
      if (i.type == 'LAND') {
        this.officialUrl = i.domain[0].url;
      }
    });
    this.queryCertificate();
  },
  methods: {
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 142,
          height: 142,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    async queryCertificate() {
      let ret = await this.$Api(queryCertificate);
      if (ret && ret.code == 200) {
        this.qrUrl = ret.data.content;
        this.generateCode(ret.data.content);
      }
    },
    showTip() {
      Toast('请手动截图保存账号凭证');
    },
  },
};
</script>

<style lang="scss" scoped>
.actCredentials {
  box-sizing: border-box;
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);

    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: url('../../../../assets/png/actCredentialsBg.png') no-repeat;
    background-size: 100% 100%;
    .actTitle {
      height: 26px;
      width: 92px;
      background: url('../../../../assets/png/actTitle.png') no-repeat;
      background-size: 100% 100%;
      margin: 17px auto;
    }
    .actBg {
      width: 318px;
      min-height: 459px;
      background: url('../../../../assets/png/actBg.png') no-repeat;
      background-size: 100% 100%;
      margin: 0px auto;
      padding-bottom: 20px;
      .topBox {
        padding-top: 20px;
        width: 100%;
        .dottedLine {
          display: flex;
          margin: 0px 10px;
        }
        .dotted {
          height: 1px;
          width: 3px;
          margin: 0 1px;
          background-color: rgb(255, 194, 103);
        }
        .dottedLine1 {
          display: flex;
          margin: 10px 10px;
        }
        .avatarImg {
          width: 60px;
          height: 60px;
          background-image: url('../../../../assets/png/appLogoCircle.png');
          background-size: 100% 100%;
          border-radius: 50%;
          margin: 7px auto;
        }
        .qrText {
          font-size: 16px;
          text-align: center;
        }
        .downloadUrlText {
          text-align: center;
          color: rgb(102, 102, 102);
          font-size: 14px;
          margin-top: 12px;
        }
        .downloadUrl {
          font-size: 28px;
          text-align: center;
          margin: 0 30px;
          box-sizing: border-box;
          word-break: break-all;
          line-height: 35px;
        }
        .qrcode {
          width: 142px;
          height: 142px;
          margin: 5px auto 0px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .tipText {
          margin: 5px 30px 0px;
          height: 44px;
          font-size: 14px;
          color: rgb(255, 95, 72);
        }
        .saveBtn {
          height: 38px;
          line-height: 38px;
          border-radius: 4px;
          margin: 8px 30px 0px;
          font-size: 16px;
          text-align: center;
          color: #fff;
          background-color: rgb(255, 103, 143);
        }
      }
    }

    .illustrateTitle {
      color: #fff;
      font-size: 16px;
      text-align: center;
      margin: 14px 0;
    }

    .illustrateText {
      font-size: 12px;
      margin: 10px 30px;
      color: rgb(255, 248, 238);
    }
  }
}
</style>
